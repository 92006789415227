.rassrochka {
    display: flex;
    justify-content: center;
    font-family: 'SF Pro Text';
}

.rassrochka h2 {
    font-size: 24px;
    font-weight: 900;
    line-height: 29px;
    color: #1C1C1C;
    margin-bottom: 15px;
}

.rassrochka .inputs {
    border-radius: 50% !important;
    min-width: 334px !important;
}

.rassrochka .inps {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 15px;
}

.rassrochka .cartaBtn {
    background: #006aff;
    border-radius: 15px;
    padding: 20px 0;
    width: 100%;
    font-family: "SF Pro Text";
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #fcfcfc;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s linear;
    margin-top: 30px;
}

.rassrochka .phoneInputBox {
    height: 56px;
    width: 100%;
    border-radius: 15px;
    border: 1px solid rgb(200, 200, 200);
    display: flex;
    align-items: center;
    justify-content: center;
}
.rassrochka .phoneInputBox:hover{
    border-color: #1C1C1C;
}

.rassrochka .form-control {
    font-size: 16px !important;
    font-weight: 500 !important;
}
