.streamBg .container {
    max-width: 1400px;
    margin: 0 auto;
}

.streamContainer {
    position: relative;
    display: flex;
}

.streamBg .container form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.streamBg .container form input{
    border: 1px solid rgb(26, 123, 255);
    margin: 0 5px;
    padding: 15px 50px;
    border-radius: 10px;
    font-size: 20px;
}

.streamBg .container form .streamBtn {
    padding:15px 50px;
    border: none;
    font-size: 20px;
    cursor: pointer;
    border-radius: 10px;
    font-weight: 900;
    letter-spacing: 3px;
    text-align: center;
    display: block !important;
    background-color: rgb(26, 123, 255);
    color: #fff;
}

.streamBg .container .streamTitle {
    text-align: center;
    margin: 30px 0;
    font-size: 40px;
}

.streamBg {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 90vh;
    /* background-color: #999; */
    /* background: url(../../assets//images/blurImage.jpg); */
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    /* padding: 20px; */
    /* display: grid; */
    /* align-items: center; */
    justify-content: center;
    /* flex-wrap: wrap; */
}

.streamBg .two-speaker {
    width: 50% ;
}

.streamContainer .css-o2ec71-MuiPaper-root-MuiAlert-root {
    z-index: 1;
}

.streamContainer .css-o2ec71-MuiPaper-root-MuiAlert-root .MuiAlert-icon {
    display: none;
}

/* 
.video-player {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.video-containers {
    position: relative;
    padding: 0;
    margin: 0;
    background-color: #1f1f1f8e;
    border-radius: 10px;
    width: 100%;
    height: 100%;
}

.user-uid {
    display: flex;
    align-items: center;
    column-gap: 1em;
    background-color: #1f1f1f8e;
    padding: 5px 10px;
    border-radius: 5px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 1;
    margin: 0;
    font-size: 18px;
} */


.twoChild {
  width: 50%;
}

.streamBg .streamNav {
    position: absolute;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    bottom: 0%;
    left: 0;
    height: 90px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    /* background-color: rgba(255, 255, 255, 0.3); */
    padding: 30px 20px;
    color: #fff;
    border-top: 2px solid rgb(26, 123, 255);
    gap: 5px;
    z-index: 2;
}

.streamBg .streamNav button {
    text-align: center;
    color: #fff;
    border: 1px solid #fff;
    cursor: pointer;
    background-color: rgb(26, 123, 255);
    border-radius: 10px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.streamBg .streamNav button svg{
  width: 1.5em;
  height: 1.5em;
}

.streamBg .streamNav button .navButtonsRed {
    color: red;
}

#agora-video-player-track-video-46-client-792fc_c2e47 {
    display: flex !important;
}

.userPhoto {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 90px;   
}


/* .streamBg .active-users-list {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 300px;
    background-color: blue;
    z-index: 9;
} */

.profilePhoto {
    text-align: center;
    width: 350px;
    height: 350px !important;
    background-size: cover;
    object-fit: cover;
}

.startStreamDiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 10px;
}


.nav-option-bar {
    position: relative;
    justify-content: left;
}

.navOptionsContainer {
    display: flex;
    flex-direction: column;
    opacity: 0;
}

.navOptionsContainerHide {
    opacity: 1 !important;
}

#join {
text-align: center;
}

.navOptionsContainer div {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    bottom: 100%;
    gap: 5px 0;
}

.navOptionsContainer div button {
    z-index: 2;
}

.NoPhoto {
    width: 350px;
    height: 350px;
}
.NoPhoto,
.userphotoChild {
    width: 350px;
     height: 350px;
}

.userphotoChild img {
    width: 350px;
    height: 350px;
    object-fit: cover;
}

.stream-left .container {
    padding: 0;
}


#activeUser {
    position: absolute;
    top: 20px;
    left: 10px;
    color: #fff;
    z-index: 2;
    font-weight: 900;
    font-size: 18px ;
}

.stream-about {
    padding: 20px 0;
}

.stream-about .streamTitle  {
    margin-bottom: 10px;
}

.Speaker-Stream-About {
    margin-top: 40px;
}

::-webkit-scrollbar {
    width: 10px;
    height: 7px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #006AFF;
    border-radius: 10px;
}

#youtubePlayerMark3,
#youtubePlayerMark2,
#youtubePlayerMark {
    position: absolute;
    bottom: 0px;
    right: 40px;
    background-color: transparent;
    width: 50%;
    height: 45px;
}

.ytp-chrome-controls .ytp-button.ytp-youtube-button, .ytp-small-mode .ytp-chrome-controls .ytp-button.ytp-youtube-button, .ytp-embed .ytp-chrome-controls .ytp-button.ytp-youtube-button, .ytp-embed.ytp-small-mode .ytp-chrome-controls .ytp-button.ytp-youtube-button, .ytp-dni.ytp-embed .ytp-chrome-controls .ytp-button.ytp-youtube-button {
    display: none;
}
    
#youtubePlayerMark2 {
    top: 0;
    width: 100%;
    right: 0;
    height: 80px;
}


.youtube-container {
	overflow: hidden;
	width: 100%;
	aspect-ratio: 16/9;

    .salom {
        width: 100px;
    }
}

.youtube-container iframe {
    width: 300%;
    height: 100%;
    /* ...and bring it back again */
    margin-left: -100%;
}

.youtube-container iframe .ytp-title {
display: none;
}	


@media (max-width: 1200px) {
    .streamBg .streamNav button {
        padding: 10px 10px;
    }
    
}

@media (max-width: 1000px) {
    .streamContainer {
        display: block;
        margin-bottom: 70px;
    }
    .streamBg .streamNav button {
        padding: 5px;
    }
  
    .streamBg .streamNav {
            padding: 10px 0;
    }
    .startStreamDiv br {
        display: none;
    }

    #activeUser {
        top: 50px;
        left: 10px;
    }

    .YoutubeTrans {
        margin-top: 50px;
    }
}

@media (max-width: 767px) {
.stream-about .col-sm-24 {
    width: calc(100% / 24 * 8);
    padding: 0px 15px;

}
.stream-about .streamTitle h3,
.stream-about .spiker_title{
    font-size: 20px;
    line-height: 30px;
}
}
@media (max-width: 650px) {
    .streamBg {
        padding: 0 !important;
        height: 50vh;
    }

    .profilePhoto {
        width: 220px;
        height: 220px !important;
    }
    
  
    .NoPhoto,
    .userphotoChild {
        width: 220px;
         height: 220px;
    }
    
    .userphotoChild img {
        width: 220px;
        height: 220px;
    }
}

@media (max-width: 650px) {
    .stream-about .col-sm-24 {
        width: calc(100% / 24 * 15);
        padding: 0px 15px;
    }
}


@media (max-width: 500px) {
    .stream-about .col-sm-24 {
        width: calc(100% / 24 * 24);
        padding: 0px 15px;
    }
    .streamContainer {
        margin-bottom: 10px;
    }
}
