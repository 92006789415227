

.vs-nav {
    background-color: #F9F9F9;
    position: fixed;
    top: 80px;
    left: 0;
    height: 100%;
    color: #1C1C1C;
    width: 250px;
    border-right: 1px solid #D9D9D9;
    transition: width 0.5s;
    overflow-x: hidden;
    padding-top: 40px;
    z-index: 9;
}

.vs-nav ul li{
    padding: 0px 20px;
    margin-bottom: 20px;
    font-weight: 700;
    width: 250px;
}

.vs-nav ul li:hover{
    color: red;
    cursor: pointer;
}

.vs-nav li.active {
    color: red;
}

.vs-nav.active {
    width: 70px;
    display: flex;
    flex-wrap: nowrap;
}

.vs-nav.active li {
    word-wrap:initial;
}

@media screen and (max-width: 800px) {
    .vs-nav ul li {
        padding: 0 15px;
    }

    .vs-nav.active {
        width: 0px;
    }
}