html {
  /* overflow: visible !important; */
  overflow-y: scroll;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  /* font-family: sans-serif; */
  list-style: none;
  text-decoration: none;
  font-family: "SF Pro Text", sans-serif !important;
}
.t-decor-none {
  text-decoration: none;
}
.link-navigate {
  text-decoration: underline !important;
}

/* .aspect_ratio {
} */
@media (max-width: 576px) {
  html {
    overflow-x: hidden !important;
  }
}

/* .globalClass_e830 jdiv, */
/* jdiv jdiv jdiv, */
.__jivoMobileButton,
._orientationLeft_ac3b,
._orientationLeft_ac3b.wrap_f5e9,
.wrap_f5e9 {
  right: 10px !important;
  bottom: 70px;
  left: auto !important;
}

.button_c7ee {
  transform: translateY(-65px) !important;
}