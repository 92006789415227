.taqvims {
    margin: 20px 0;
}

.taqvims .taqivim-title h3{
    border-radius: 15px 15px 0 0;
    width: 100%;
    text-align: center;
    background-color: rgba(0, 106, 255, 1);
    padding: 10px 0;
    color: #fff;
}


.taqvims .ramadan-taqvims-list table  {
    width: 100%;
    text-align: center;
}
.taqvims .ramadan-taqvims-list table tr th {
    padding: 12px 10px;
    font-weight: 900;
}
.taqvims .ramadan-taqvims-list table tr td {
    padding: 10px !important;
}

.taqvims .ramadan-taqvims-list table tr:nth-child(odd) {
    background-color: #e1dbdb;
}

.Ramadan-blessing  {
    margin-top: 30px;
}

.Ramadan-blessing h3 {
    text-align: center;
}

.Ramadan-blessing h4 {
    text-align: end;
    margin: 30px 0;
}

.Ramadan-blessing h6 {
    font-size: 25px;
    line-height: 150%;
    margin-bottom: 30px;
}

.Ramadan-blessing p {
    font-size: 20px;
    font-weight: 600;
}

.Ramadan-blessing .first-blessing {
    margin-bottom: 70px;
}

.taqvims tr.activeDate{
    background-color: rgba(0, 106, 255, 1) !important;
    color: #fff;
}


@media screen and (max-width: 700px) {
    .ramadon-taqvims {
        padding: 0 !important;
    }
}

@media (max-width: 500px) {
    .taqvims .taqivim-title h3{
       margin-top: 60px;
    }
   
    .taqvims .ramadan-taqvims-list table tr td,
    .taqvims .ramadan-taqvims-list table tr th {
        padding: 5px !important;
    }
}

@media (max-width: 360px) {
   
   
    .taqvims .ramadan-taqvims-list table tr td,
    .taqvims .ramadan-taqvims-list table tr th {
        padding: 0px !important;
    }
}