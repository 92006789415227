header .rowGrid {
  flex-wrap: nowrap;
}

header {
  width: 100%;
  height: 10px !important;
  font-family: sans-serif !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 111;
  background-color: white;
}

header img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

/*  universal-codes */

header .bars {
  /* width: 88px !important; */
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: 0px !important;
  padding-left: 30px;
}

header .courseUploadButton {
  position: static;
  margin: 0;
  margin-right: 20px !important;
}

header .bgBlue {
  padding: 8px 20px !important;
  border: none;
  color: rgba(252, 252, 252, 1);
  font-size: 16px;
  font-family: "Pro-Meduim", "serif";
  line-height: 19px;
  font-weight: 500;
  background-color: rgba(0, 106, 255, 1);
  border-radius: 15px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  cursor: pointer;
}

header #courses {
  position: relative;
}

header .courseMenu {
  position: relative;
}

.navbar .menuBar {
  padding: 20px 30px;
}

header .menuBar .title {
  margin-bottom: 20px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 24px;
  color: #1c1c1c;
  cursor: pointer;
}

.subMenu {
  /* display: none !important; */
  position: absolute;
  top: 0;
  right: -375px;
  bottom: 0;
  background: #fcfcfc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  width: 370px;
  padding: 20px 30px !important;
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
}

.subMenu li {
  margin-bottom: 16px;
}

.menuBarItem {
  padding: 0 30px,
}

.subMenu li a {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1c1c1c;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1dmzujt {
  overflow: visible !important;
}

.subMenuactive {
  color: #006aff !important;
  transition: all 1s;
}

header .menuBar .title svg {
  margin-left: 99px;
}

header .bgBlue span {
  margin-left: 12px;
  display: block;
}

header nav {
  width: 100%;
  padding: 7px 0 !important;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 111;
  background-color: white;
}

header nav .logo {
  margin-right: 40px;
  width: 120px !important;
}

header nav .logo .logoImg {
  max-width: 100%;
  width: 300px !important;
  min-width: 100px !important;
}

header nav form {
  position: relative;
  width: 100%;
}

header nav .search {
  height: 40px;
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 15px;
  overflow: hidden;
  padding: 0 15px;
}

header nav .search input {
  height: 99%;
  position: absolute;
  top: 0;
  right: 10px;
  left: 60px;
  border: none;
  outline: none;
  background-color: transparent;
  width: 65%;
}

header nav .search .searchIcon {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

header nav .searchResponse {
  position: absolute;
  height: auto;
  width: 100%;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  top: 50px;
  left: 0;
  overflow-x: hidden;
  padding: 15px;
  scrollbar-color: #d9d9d9;
  scrollbar-width: thin;
  scroll-behavior: smooth;
}

header nav .moreInfo {
  overflow-y: scroll !important;
  height: 300px !important;
}

header nav .searchResponse .rowGrid {
  padding: 20px 0;
}

header nav .searchResponse .rowGrid:hover {
  background-color: #f1f1f1;
}

header nav .searchResponse .name {
  color: #006aff;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin-bottom: 10px;
}
.menuBar {

}

header nav .searchResponse .teacher {
  color: #1c1c1c;
  font-size: 14px;
}

/* header nav .searchResponse::-webkit-scrollbar {
  width: 10px;
}

header nav .searchResponse::-webkit-scrollbar-track {
  background: #d9d9d9;
}

header nav .searchResponse::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: #6f6c6c;

} */

header nav .searchResponse img {
  width: 80px !important;
  margin-left: 10px !important;
  aspect-ratio: 16/9 !important;
  object-fit: contain;
}

header nav .filter_item .filter_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #80b5ff;
  margin-bottom: 21px;
}

header nav .filter_item form input {
  display: none;
}

header nav .filter_item form label {
  position: relative !important;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #1c1c1c;
  cursor: pointer;
  border-radius: 5px;
}

header nav .filter_item form svg {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
}

header nav .filter {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.p-relative {
  position: relative;
}

header nav .filter span {
  margin-left: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  font-family: "Pro-Medium", serif;
  color: rgba(28, 28, 28, 1);
}

header nav {
  position: relative;
}

header nav .filter_item {
  background: #fcfcfc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  position: absolute;
  top: 48px;
  left: 0;
  width: 350px;
  padding: 30px;
}

header nav .filter_item .price {
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 21px;
  padding-bottom: 22px;
}

header nav .filter_item .price .price_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: 13px;
}

header nav .filter_item .price .price_title p {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #1c1c1c;
}

/* price end  */
header nav .filter_item .rating_title {
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 20px;
  padding-bottom: 22px;
}

header nav .filter_item .rating_title .stark {
  margin-top: 13px;
}

header nav .filter_item .rating_title .stark p {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #999999;
}

header nav .filter_item .rating_title .stark p span {
  color: #006aff;
  margin-left: 12px;
  margin-right: 5px;
}

header nav .filter_item .rating_title .stark {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

/* rating_title end  */
header nav .filter_item .language {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 22px;
}

header nav .filter_item .language .language_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: 13px;
}

header nav .filter_item .language .language_title p {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #1c1c1c;
}

header nav .courseDownload {
  display: flex;
  align-items: center;
  font-family: "Pro-Medium", serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #1c1c1c;
  cursor: pointer;
}

header .loggenInBtn {
  padding-left: 0;
}

header nav .filterContainer,
header nav .speakerBlockContainer {
  padding: 0;
}

header nav .speakerBlock:hover .sp-popup {
  transform: scale(1);
}

header nav .sp-popup {
  position: absolute;
  top: 40px;
  width: auto;
  padding: 20px;
  background-color: white;
  border-radius: 15px;
  width: 300px;
  right: 10px;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.4);
  transition: all 0.1s ease-in;
  transform: scale(0);
  font-size: 14px;
  text-align: center;
}

header nav .courseDownload p {
  margin-left: 10px;
}

header nav .eduBranding,
header nav .shopBag {
  font-family: "Pro-Medium", serif;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: rgba(28, 28, 28, 1);
}

header nav .relative {
  position: relative !important;
}

header nav .cartLength {
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: #80b5ff;
  border-radius: 50%;
  font-size: 11px;
  color: white;
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

header nav .langDropDown {
  padding: 10px 12px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 106, 255, 1);
  border-radius: 15px;
  color: rgba(0, 106, 255, 1);
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  font-family: "Pro-Medium", serif;
}

header nav .langDropDown span {
  margin-left: 10px;
}

header nav .langDropDown-menu {
  display: none;
}

header nav .col-6 .bgBlue {
  font-size: 20px;
  line-height: 24px;
}

header nav .avatar {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  border: none !important;
  position: relative;
}

.speakerButton p {
  margin-left: 0 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  /* line-height: 19px !important; */
  color: #1c1c1c;
  transition: all 0.1s linear;
}

.speakerButton p:hover {
  color: #006aff;
}

.speakerPopover p:nth-child(1) {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.speakerPopover p:nth-child(2) {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
}


.navbar .see-btn p {
  font-size: 16px;
  color: #fff;
  border-radius: 15px;
  padding: 5px 10px;
  margin-right: 20px;
  background-color: #006aff;
  z-index: 1;
}

.navbar .see-btn p:hover {
  cursor: pointer;
}

.css-1inpwb6 .MuiOutlinedInput-notchedOutline {
  height: 100% !important;
}

@media screen and (max-width: 1300px) {
  header nav .logo {
    margin-right: 30px;
  }

  header .bgBlue {
    padding: 10px 15px;
    font-size: 16px;
  }

  header nav .search button {
    position: absolute;
    top: 50%;
    left: 12px;
  }

  header nav .courseDownload p {
    margin-left: 0;
  }

  .navbar {
    padding-left: 13px;
  }
}


@media screen and (max-width: 1150px) {
  .navbar {
    padding-left: 15px;
  }
}

@media screen and (max-width: 1100px) {
  .navbar .see-btn p {
    margin-right: 0px;
  }
}

@media screen and (max-width: 991px) {
  header {
    display: none;
  }
}