.moduleUpload .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active,
.moduleUpload .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: #006AFF !important;
}

.moduleUpload .css-1f5ro5o-MuiButtonBase-root-MuiButton-root {
    background-color: #80B5FF !important;
}

.moduleUpload .css-1f5ro5o-MuiButtonBase-root-MuiButton-root:hover {
    background-color: #006AFF !important;
}

.moduleUpload .headerText {
    font-size: 24px;
    margin: 20px 0 10px;
}

.moduleUpload .rectangle{
    margin-bottom: 30px;
}

.moduleUpload .moduleItem {
    border: 2px solid #D9D9D9;
    border-radius: 15px;
    padding: 0 18px;
    position: relative;
    margin-bottom: 30px;
    height: 59px;
    display: flex;
    align-items: center;
}

.moduleUpload .moduleItem:last-child {
    margin-bottom: 0;
}

.moduleUpload .moduleItemName {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #1C1C1C;

}

.moduleUpload .moduleItem .label {
    position: absolute;
    left: 7px;
    top: -14px;
    background-color: white;
    padding: 3px 5px;
    font-weight: 300;
    font-size: 12px;
    line-height: 19px;
    color: #1C1C1C;
}

.moduleUpload .moduleInput {
    width: 100%;
    height: 100%;
    margin-left: -18px;
    border: none;
    background-color: transparent;
    outline: none;
    padding-left: 18px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #1C1C1C;
}
@media (max-width:576px){
    .moduleUpload .moduleItem{
        margin-bottom:  0 !important;
    }
    .moduleUpload .moduleItem svg{
        margin-right:  0 !important;
    }
    .moduleUpload .moduleItem .label {
        top: -10px !important;
        padding: 0 5px !important;
    }
    .css-1mz1l2x-MuiStepContent-root{
        margin: 0 !important;
        padding: 0 !important;
    }
}