.coursesFilter .navBotMenu{
    margin-right: -30px !important;
}
.coursesFilter .col-6{
    overflow-x: visible !important;
}
@media (max-width:576px) {
    .coursesFilter {
        padding-top: 140px;
    }
}

@media (min-width: 1600px) {
    .col-6 {
        margin: 10px 0;
    }
}
