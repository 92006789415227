        .statistics .headerRow h1 {
            font-weight: 900;
            font-size: 24px;
            line-height: 33px;
            color: #1c1c1c;
            display: flex !important;
            align-items: center !important;
        }
        
        .statistics .headerRow h1 span {
            margin-left: 12px;
        }
        
        .statistics .headerRow .col-24 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;
        }
        
        .statistics .headerRow .filter {
            width: 171px;
            height: 48px;
            border-radius: 15px;
            border: 1px solid #d9d9d9;
            display: flex;
            align-items: center;
            padding: 15px;
            justify-content: space-evenly;
        }
        
        .statistics .headerRow .filter p {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-align: right;
            color: #1c1c1c;
        }
        
        .statistics .links_line {
            display: flex;
            flex-direction: column;
        }
        
        .statistics .line_span {
            margin-top: 20px;
            margin-bottom: 38px;
        }
        
        .statistics .links {
            height: 70px;
            display: flex;
            align-items: center;
            border-bottom: 2px solid rgba(17, 17, 17, 0.06);
        }
        
        .statistics .links p {
            color: #999999;
            margin: 0 20px 0 0;
            text-align: none;
            height: 101%;
            display: flex;
            align-items: center;
            position: relative;
        }
        
        .statistics .links p:first-child {
            color: #006aff;
            /* border-bottom: 3px solid #006aff; */
        }
        
        .statistics .links p:first-child::after {
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            bottom: -2px;
            left: 0;
            right: 0;
            position: absolute;
            background-color: #006aff;
            z-index: 2;
        }
        
        .statistics .css-19kzrtu {
            padding: 0 15px !important;
            margin-top: 20px;
        }
        
        /* .statistics .MuiTabs-flexContainer {
            height: 0px !important;
        }
         */
        .MuiButtonBase-root {
            font-weight: 400;
            font-size: 20px;
            line-height: 150%;
            text-transform: capitalize !important;
            display: flex;
            align-items: center;
            color: #999999;
        }
        
        @media screen and (max-width: 576px) {
            .statistics .headerRow h1 {
                font-size: 16px;
                line-height: 19px;
                /* padding: 0 15px; */
            }
            .statistics .col-24 {
                padding: 0 15px !important;
            }
            .finance .col-24 {
                padding: 0 !important;
            }
            .statistics .headerRow .filter {
                width: 112px;
                height: 24px;
                border-radius: 0px;
                border: none;
                display: flex;
                justify-content: center;
                padding: 0px;
            }
            .statistics .headerRow .filter p {
                font-size: 14px;
                line-height: 17px;
                margin-left: 0px;
            }
            .css-19kzrtu {
                padding: 0 !important;
                margin-top: 20px;
            }
            .statistics .css-19kzrtu {
                padding: 0 !important;
            }
        }