.vs-Chat {
    position: fixed;
    top: 0;
    right: 0px;
    height: 400px;
    width: 300px;
    background-color: #fff;
    z-index: 10;
    border-radius: 15px;
    padding: 20px 30px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
}

@media screen and (max-width: 800px){
    .vs-Chat {
        width: 100%;
    }
}