.faq {
  /* margin-top: 130px; */
  padding: 80px 24px 24px 24px;
}

.faq .faqTitle {
  font-family: "SF Pro Text";
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  color: #1c1c1c;
}

.faq .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  /* margin: 0 !important; */
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 26px 16px 16px 16px !important;
}

.faq .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  margin: 20px 0 !important;
  border-radius: 10px !important;
  box-shadow: none !important;
}

.faq .css-pwcg7p-MuiCollapse-root {
  background-color: white !important;
}

.faq .MuiButtonBase-root {
  border-radius: 15px !important;
  height: 72px !important;
  background-color: #f6f6f6 !important;
  font-size: 18px !important;
}

.faq
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.css-67l5gl {
  /* box-shadow: 1px 1px 1px black !important; */
  /* background-color: black !important; */
}
.faq
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
  /* background-color: black !important; */
}
@media screen and (max-width: 991px) {
  .faq {
    /* margin-top: 130px; */
    padding: 80px 24px 24px 24px;
  }
  .faq .faqTitle {
    font-family: "SF Pro Text";
    font-weight: 600;
    font-size: 30px;
    line-height: 43px;
    color: #1c1c1c;
  }
}

@media screen and (max-width: 576px) {
  .faq .MuiButtonBase-root {
    border-radius: 10px !important;
    height: 60px !important;
    background-color: #f6f6f6 !important;
  }

  .faq {
    padding: 80px 0px 64px 0px;
  }

  .faq .faqTitle {
    font-size: 20px;
    line-height: 24px;
  }
}
