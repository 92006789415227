/* header */

header .rowGrid {
  flex-wrap: nowrap;
}

header {
  width: 100%;
  height: 100px;
  font-family: sans-serif !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 111;
  background-color: white;
}

header img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

/*  universal-codes */

header .bars {
  /* width: 100px; */
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: -15px;
}

header .bgBlue {
  padding: 12px 20px;
  border: none;
  color: rgba(252, 252, 252, 1);
  font-size: 16px;
  font-family: "Pro-Meduim", "serif";
  line-height: 19px;
  font-weight: 500;
  background-color: rgba(0, 106, 255, 1);
  border-radius: 15px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  cursor: pointer;
}
header #courses {
  position: relative;
}

header .courseMenu {
  position: relative;
}
/* header  .menu_ul .menuBar {
  padding: 30px;
  padding-bottom: 200px;
  width: 370px;
  background: #fcfcfc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  /* display: none; */
/* } */
header .menuBar .title {
  margin-bottom: 24px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1c1c1c;
  cursor: pointer;
}
.subMenu {
  /* display: none !important; */
  position: absolute;
  top: 0;
  right: -375px;
  bottom: 0;
  background: #fcfcfc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  width: 370px;
  padding: 32px;
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.subMenu li a {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1c1c1c;
}
.subMenuactive {
  color: #006aff !important;
  transition: all 1s;
}
header .menuBar .title svg {
  margin-left: 99px;
}

header .bgBlue span {
  margin-left: 12px;
  display: block;
}
header nav {
  width: 100%;
  padding: 26px 0px;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111;
  background-color: white;
}

header nav .logo {
  margin-right: 40px;
  width: 120px !important;
  padding: 0 15px;
}
header nav .logo .logoImg {
  max-width: 100%;
  width: 300px !important;
}

/* header nav .search {
  width: 100%;
  height: 50px;
  position: relative;
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 15px;
  overflow: hidden;
  padding: 0;
}

header nav .search button {
  border: none;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

header nav .search input {
  width: 100%;
  border: none;
  height: 100%;
  position: absolute;
  left: 60px;
  background-color: transparent;
  color: rgba(153, 153, 153, 1);
  font-size: 16px;
  line-height: 19px;
  font-family: "Pro-Medium", serif;
  font-weight: 500;
}

header nav .search input:focus {
  outline: none;
} */


header nav .filter_item .filter_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #80b5ff;
  margin-bottom: 21px;
}
header nav .filter_item form input {
  display: none;
}
header nav .filter_item form label {
  position: absolute;
  top: 3.5px;
  right: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #1c1c1c;
  cursor: pointer;
  border-radius: 5px;
}
header nav .filter_item form svg {
  position: absolute;
  top: 9.5px;
  right: 5px;
}
/* header nav .filter_item{
  overflow: auto;
}  */
/* overflow ishlamadi ko'rish kere^^^^^ */

/* unversal code  */

header nav .filter {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.p-relative {
  position: relative;
}
header nav .filter span {
  margin-left: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  font-family: "Pro-Medium", serif;
  color: rgba(28, 28, 28, 1);
}

header nav {
  position: relative;
}
header nav .filter_item {
  background: #fcfcfc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  position: absolute;
  top: 48px;
  left: 0;
  width: 350px;
  padding: 30px;
}
header nav .filter_item .price {
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 21px;
  padding-bottom: 22px;
}
header nav .filter_item .price .price_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: 13px;
}
header nav .filter_item .price .price_title p {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #1c1c1c;
}
/* price end  */
header nav .filter_item .rating_title {
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 20px;
  padding-bottom: 22px;
}
header nav .filter_item .rating_title .stark {
  margin-top: 13px;
}
header nav .filter_item .rating_title .stark p {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #999999;
}
header nav .filter_item .rating_title .stark p span {
  color: #006aff;
  margin-left: 12px;
  margin-right: 5px;
}
header nav .filter_item .rating_title .stark {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
/* rating_title end  */
header nav .filter_item .language {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 22px;
}

header nav .filter_item .language .language_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: 13px;
}
header nav .filter_item .language .language_title p {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #1c1c1c;
}
/* language end */

/* filter */

header nav .courseDownload {
  display: flex;
  align-items: center;
  font-family: "Pro-Medium", serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #1c1c1c;
  cursor: pointer;
}
header nav .courseDownload p {
  margin-left: 10px;
}

header nav .eduBranding,
header nav .shopBag {
  font-family: "Pro-Medium", serif;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: rgba(28, 28, 28, 1);
}
header nav .relative {
  position: relative !important;
}
header nav .cartLength {
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: #006aff;
  border-radius: 50%;
  font-size: 14px;
  color: white;
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

header nav .langDropDown {
  padding: 10px 12px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 106, 255, 1);
  border-radius: 15px;
  color: rgba(0, 106, 255, 1);
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  font-family: "Pro-Medium", serif;
}

header nav .langDropDown span {
  margin-left: 10px;
}

header nav .langDropDown-menu {
  display: none;
}

header nav .col-6 .bgBlue {
  font-size: 16px !important;
  line-height: 20px;
}
header nav .avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none !important;
  position: relative;
}

@media screen and (max-width: 1300px) {
  .search > input::placeholder {
    /* color: transparent; */
    /* opacity: 0; */
  }
  header nav .logo {
    margin-right: 30px;
  }
  header .bgBlue {
    padding: 10px 15px;
    font-size: 16px;
  }
  header nav .search button {
    position: absolute;
    top: 50%;
    left: 12px;
  }
  header nav .courseDownload p {
    margin-left: 0;
  }
}
.justify-content-between{
  justify-content: space-between !important;
}
.gap-1 {
  gap: 1rem;
}
.gap-2 {
  gap: 2rem;
}
.gap-3 {
  gap: 3rem;
}
.width-100 {
  width: 100%;
}
.w-150 {
  width: 150px;
}

.text-center {
  text-align: center;
}