.uploadedVideos {
    margin: 50px 0 40px !important;
    display: block;
}

.uploadedVideos li {
    margin: 15px 0;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
}

.uploadedVideos li::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -5px;
    height: 2px;
    left: 0;
    right: 0;
    background-color: black;
}

.loader {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(255, 255, 255, .7);
    width: 100vw;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #006AFF;
    font-size: 30px;
}

.loader h3 {
    display: flex;
    position: relative;
}
.addVideoButton{
    margin-top: 20px;
    font-size: 24px;
    letter-spacing: 1px;
}
.border-red{
    border-color: red !important;
}
@media (max-width:576px) {
    .mb-sm-15{
        margin-bottom: 15px !important;
    }
    .rectangle{
        padding: 10px !important;
    }
}
