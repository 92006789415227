.sidebarsm{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 999999999;
    padding: 10px 25px 8px;
    border-top: 1px solid #d9d9d9;
}
.sidebarsm ul{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sidebarsm ul li{
    display: block;
    text-align: center;
}
.sidebarsm ul li p{
    font-size: 10px;
    margin-top: 7px;
}
.sidebarsm .favourite .favCount {
    position: relative !important;
}
.sidebarsm .count {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #80B5FF;
    color: white;
    font-size: 11px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -8px;
    right: 0px;
  }
  .sidebarsm .active {
    color: #006aff;
  }