.rasCourse {
    display: flex;
    gap: 20px;
    font-family: 'SF Pro Text';
}

.rasCourseInfo h4 {
    font-size: 20px;
    font-weight: 500;
    color: #006AFF;
}

.rasCourseInfo>span {
    font-size: 16px;
    font-weight: 300;
    /* text-decoration: underline; */
    color: #1C1C1C;
    margin-bottom: 15px;
}

.rasCourseInfo .mar {
    margin-top: 10px;
}

.rasCourseInfo p {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.rasCourseInfo p span {
    font-size: 16px;
    font-weight: 400;
}

.rasCourseInfo button {
    padding: 12px 20px;
    border-radius: 15px;
    background-color: #006AFF;
    color: white;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.rasCourse .taff {
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;



    font-family: SF Pro Text;
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;


}