.statistic .boxes_line {
  display: flex;
  margin-bottom: 30px;
}

.speakerComments .box {
  display: flex;
  align-items: center;
  /* width: 385px; */
  height: 100px;
  background: #f6f6f6;
  border-radius: 15px;
}

.speakerComments .box_item .img {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
  border-radius: 50%;
  background-color: #bfdaff;
}

.speakerComments .box_item .img svg {
  width: 24px;
  height: 24px;
}

.speakerComments .box_item {
  display: flex;
  align-items: center;
  padding: 0 25px;
}

.speakerComments .box_p h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0statistic 0.05em;
  color: #006aff;
  margin-top: 8px;
}

.speakerComments .profit_box p {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #999999;
}

.speakerComments .profit_box {
  display: flex;
}

.speakerComments .profit {
  display: flex;
}

.speakerComments .profit p {
  margin-left: 5px;
  
}

.speakerComments .boxes {
  width: 242px;
  height: 56px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}

.speakerComments .line {
  width: 100%;
  height: 2px;
  background-color: rgba(17, 17, 17, 0.06);
  margin: 30px 0 50px;
}

.grapth_rectangle {
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 15px;
  padding: 30px;
  margin-top: 50px;
  display: none;
}

.speakerComments .chart {
  width: 100% !important;
  display: none;
}

.speakerComments .chart svg {
  width: 100% !important;
  left: 0 !important;
  right: 0 !important;
}

.speakerComments .text-center {
  text-align: center;
}

.speakerComments h1 + p {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #1c1c1c;
  margin-bottom: 30px;
}
@media screen and (max-width: 576px) {
  .speakerComments .box {
    display: flex;
    align-items: center;
    height: 90px;
    background: #f6f6f6;
    border-radius: 15px;
  }
  .statistic .boxes_line p {
    font-size: 14px !important;
    line-height: 17px !important;
  }

  .speakerComments .box_p h2 {
    font-size: 20px;
    line-height: 24px;
    margin-top: 5px;
  }
}
