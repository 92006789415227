.vs-paymets-container {
    display: grid;
    grid-template-columns: 60% 35%;
    justify-content: space-between;
}

.vs-paymets-container .vs-paymets-box {
    border-radius: 10px;
    border: 2px solid var(--light, #D9D9D9);
    background: var(--light-card, #F6F6F6);
    box-shadow: 0px 0px 55px 5px rgba(0, 0, 0, 0.10);
    padding: 20px;
    height: 400px;
    overflow-y: scroll;
}

.vs-paymets-container .vs-paymets-box::-webkit-scrollbar {
    width: 7px;
}

.vs-paymets-container .vs-paymets-box::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(255, 0, 0, 0.555);
}

.vs-paymets-container .vs-paymets-box::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #D9D9D9;
}

.vs-paymets-container .vs-paymets-box .vs-paymets-box-content,
.vs-paymets-container .vs-paymets-box .vs-paymets-box-title {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    color: var(--black, #1C1C1C);
}

.vs-paymets-container .vs-paymets-box .vs-paymets-box-title {
    border-bottom: 5px solid var(--error-case, #EF2B23);
    margin-bottom: 10px;
    font-weight: 900;
}

.vs-paymets-container .vs-paymets-box .vs-paymets-box-content {
    border-bottom: 3px solid var(--error-case, #EF2B23);
    padding: 15px 0;
}

.vs-paymets-container .vs-paymets-box .vs-paymets-box-content p {
    font-weight: 600;
}

.vs-paymets-container .vs-paymets-balans .vs-paymets-balans-container {
    border-radius: 10px;
    border: 2px solid #D9D9D9;
    background: #FFF;
    box-shadow: 0px 0px 55px 5px rgba(0, 0, 0, 0.10);
    padding: 20px;
    color: #1C1C1C;
}

.vs-paymets-container .vs-paymets-balans .vs-paymets-balans-container .vs-paymets-balans-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 30px;
}

.vs-paymets-container .vs-paymets-balans .vs-paymets-balans-container .vs-paymets-balans-box .vs-paymets-real-balans {
    color: red;
    border-left: 3px solid red;
    padding-left: 5px;
}

.vs-paymets-container .vs-paymets-balans .vs-paymets-balans-container .vs-paymets-balans-box .vs-paymets-noreal-balans {
    color: #F19D3B;
    border-left: 3px solid #F19D3B;
    padding-left: 5px;
}

.vs-paymets-container .vs-paymets-balans .vs-paymets-add-balans {
    display: flex;
    flex-direction: column;
}

.vs-paymets-container .vs-paymets-balans .vs-paymets-add-balans button {
    border-radius: 10px;
    border: 2px solid var(--main-blue, red);
    background: #FFF;
    padding: 10px 0;
    color: red;
    font-weight: 600;
    margin-top: 20px;
    cursor: pointer;
    transition: .7s ease;
}

.vs-paymets-container .vs-paymets-balans .vs-paymets-add-balans button:hover {
    background-color: red;
    color: #fff;
}

.vs-modal-grid {
    display: grid;
    grid-template-columns: 56% 40%;
    margin: 20px 0;
    justify-content: space-between;
}

.py-Otp {
    /* opacity: 0; */
    position: absolute;
    bottom: -100%;
    transition: 1s ease-in linear;
}

.py-Otp.active {
    position: relative;
}

.vs-py-modal .password {
    position: relative;
}

.vs-py-modal #inputNum{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
  color: #1c1c1c;
  z-index: 5;
  font-size: 20px;
  background-color: transparent !important;
}

.vs-py-modal .eye {
  /* width: 19.57px;
  height: 16.55px; */
  font-size: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  color: gray;
  z-index: 5;
  background-color: transparent !important;
}

.p-horizontal15 {
    padding: 0 15px;
}

.pBottom20 {
    padding-bottom: 20px;
}

@media screen and (max-width: 1250px) {
    .vs-paymets-container {
        display: block;
    }

    .vs-paymets-container .vs-paymets-box {
        margin-bottom: 20px;
    }

    .vs-paymets-container .vs-paymets-box .vs-paymets-box-title h3{
        font-weight: 700;
        font-size: 24px;
        line-height: normal;
    }
}

@media screen and (max-width: 700px) {
    .vs-paymets-container .vs-paymets-box .vs-paymets-box-title h3{
        font-weight: 600;
        font-size: 20px;
        line-height: normal;
    }

    .vs-paymets-container .vs-paymets-box {
        margin-bottom: 20px;
        padding: 10px 5px;
    }

    .vs-paymets-container .vs-paymets-box .vs-paymets-box-content {
        border-bottom: 3px solid var(--error-case, #EF2B23);
        padding: 15px 2px;
    }

    .vs-big-container .container {
        padding: 0;
    }

    .vs-py-modal {
        display: block;
    }

    .vs-py-modal .py-Otp {
        margin-top: 20px;
    }

    #modal {
        width: 300px !important;
    }
}

@media screen and (max-width: 500px) {
    .vs-paymets-container .vs-paymets-box .vs-paymets-box-title h3{
        font-size: 18px;
        line-height: normal;
        text-align: center;
    }
    .vs-paymets-container .vs-paymets-box .vs-paymets-box-content p {
        font-weight: 400;
        font-size: 13px;
        text-align: center;
        line-height: normal;
    }

}