.DownloadedCourses .Courserow h5 {
  font-family: "SF Pro Text";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #006aff !important;
  /* cursor: pointer; */
  margin-top: 20px;
}
.DownloadedCourses.DownloadedCourses .Courserow p {
  font-family: "SF Pro Text";
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #1c1c1c;
  margin-top: 28px;
  text-decoration: underline;
  cursor: pointer;
}
.DownloadedCourses.DownloadedCourses .Courserow h5 {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-top: 10px;
}
.DownloadedCourses .Courserow .col-6 {
  margin-bottom: 37px;
}
.DownloadedCourses .Courserow img {
  border: 1px solid #d9d9d9;
  border-radius: 18px;
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: contain;
}
.DownloadedCourses .Courserow .line {
  width: 100%;
  height: 2px;
  background-color: #006aff;
  margin-top: 27px;
}
.DownloadedCourses .Courserow .col-6 .begin_course_btn {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-top: 20px;
  padding: 12px 20px;
  background: #fcfcfc;
  border: 1px solid #006aff;
  border-radius: 15px;
  cursor: pointer;
}
@media screen and (max-width: 576px) {
  .DownloadedCourses .smCards {
    display: grid;
    grid-template-columns: 30% auto;
    align-items: center;
  }
  .DownloadedCourses .smCards .smCardText{
    margin-left: 20px;
  }
  .DownloadedCourses .Courserow img {
    border: none;
    border-radius: 10px;
    max-width: 130px !important;
    aspect-ratio: 16/9;
    object-fit: cover;
    height: 90px !important;
  }
  .DownloadedCourses.DownloadedCourses .Courserow h4 {
    margin-top: 0px;
  }
  .DownloadedCourses.DownloadedCourses .Courserow p {
    font-size: 14px;
    line-height: 17px;
    margin-top: 10px;
  }
}
